import {Stack} from "@mui/material";
import { CertificateBlock } from "App/template/Certificate/Block";
import { EditableGallery } from "@tracabois/connect";
import { ExternalFile } from "@tracabois/domain";

export interface ImageCardProps {
  images: ExternalFile[]
  title: string
}

export const ImageSection = (props: ImageCardProps) => {
  const {images, title} = props;
  if (images.length <= 0) return (<></>)
  return (
    <CertificateBlock title={title} withBottomMargin>
      <Stack padding="32px 32px 0px 32px" sx={{overflowX: 'auto'}}>
        <EditableGallery files={images} editable={false}/>
      </Stack>
    </CertificateBlock>
  );
}
