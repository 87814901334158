import {OuvrageIndicatorGrid} from "@tracabois/domain"
import {CertificateHeader} from "../../template/Certificate/Header";
import {CertificateColumn} from "../../template/Certificate/Column";
import {CertificateBody} from "../../template/Certificate/Body";
import {CertificatePage} from "../../template/Certificate/Page";
import {AppLoading} from "@tracabois/components";
import {OuvrageCertificate} from "../../model";
import { Feature, LineString, MultiLineString, Point, Position } from "geojson";
import { LatLngBounds, LatLngLiteral } from "leaflet";
import L from "leaflet";
import { SocialsSection } from "../socials/SocialsSection";
import { CertificateBlock } from "App/template/Certificate/Block";
import { ImageSection } from "../realization/ImageSection";
import { DescriptionSection } from "../realization/DescriptionSection";
import { RemovalZoneSection } from "../realization/RemovalZoneSection";
import { MapSection } from "../realization/MapSection";

export interface OuvrageCertificateProps {
  item: OuvrageCertificate
}

export const OuvrageCertificateSection = (props: OuvrageCertificateProps) => {
  const {item} = props

  const getBounds = (): LatLngBounds => {
    let coordinates: LatLngLiteral[] = []
  
    item.geoJson.features.forEach((feature: Feature) => {
      switch (feature.geometry.type) {
        case "Point":
          coordinates.push({
            lng: (feature.geometry as Point).coordinates[0],
            lat: (feature.geometry as Point).coordinates[1]
          })
          break;
        case "LineString" || "MultiPoint":
          (feature.geometry as LineString).coordinates.forEach((position: Position) => {
            coordinates.push({
              lng: position[0],
              lat: position[1]
            })
          })
          break
          case "MultiLineString" || "Polygon":
            (feature.geometry as MultiLineString).coordinates.forEach((positions: Position[]) => {
              positions.forEach((position: Position) => {
                coordinates.push({
                  lng: position[0],
                  lat: position[1]
                })
              })
            })
            break
        default:
          break
      }
    })

    const lngsDescending = coordinates.map(coord => coord.lng).sort((a, b) => b - a)
    const latsDescending = coordinates.map(coord => coord.lat).sort((a, b) => b - a)

    const southWestBound = L.latLng(latsDescending[latsDescending.length - 1], lngsDescending[lngsDescending.length - 1])
    const northEastBound = L.latLng(latsDescending[0], lngsDescending[0])

    return L.latLngBounds(southWestBound, northEastBound)
  }

  const bounds = getBounds()

  if (!item) {
    return <AppLoading/>
  }

  return (
    <CertificatePage>
      <CertificateHeader title={item.realization?.name || ""} certificateType="Ouvrage" productType={item.name}/>
      <CertificateBody image={item.images[0]}>
        <CertificateBlock withVerticalPadding withHorizontalMargin withBottomMargin withBorderRadius withBoxShadow>
          <CertificateColumn>
            <OuvrageIndicatorGrid indicators={item.indicators}/>
            <DescriptionSection description={item.description}/>
            <MapSection geoJson={item.geoJson} bounds={bounds}/>
          </CertificateColumn>
          <CertificateColumn>
            <RemovalZoneSection items={item.removalZones}/>
          </CertificateColumn>
        </CertificateBlock>
        <ImageSection images={item.images} title="Photos de l'ouvrage"/>
        <SocialsSection ouvrageCertificate={item}/>
      </CertificateBody>
    </CertificatePage>
  )
}
