import {useParams} from "react-router";
import {RealizationCertificateSection} from "../../components/realization/RealizationCertificateSection";
import {AppLoading} from "@tracabois/components";
import {useRealizationCertificateGetQuery} from "../../api";

export interface RealizationProps {
}

export const RealizationPage = (_: RealizationProps) => {
  const {realizationId = ""} = useParams<{ realizationId: string }>();
  const realizationResult = useRealizationCertificateGetQuery({ id: realizationId })
  if(realizationResult.isLoading) {
    return (<AppLoading />)
  }
  return (
    <div style={{
      background: "linear-gradient(180deg, rgba(87, 69, 74, 0.10) 7.96%, rgba(87, 69, 74, 0.00) 18.4%, #FFFDF8 32.84%, #FFFDF8 64.51%)",
      display: "flex",
      justifyContent: "center",
      }}>
      <RealizationCertificateSection item={realizationResult.data!!} />
    </div>
  );
}
