import { theme } from "@tracabois/domain";
import { SocialsCard } from "./SocialsCard";
import { Stack } from "@mui/material";
import treeRingsImg from "../../asset/tree_rings.svg";
import { OuvrageCertificate, RealizationCertificate } from "App/model";

export interface SocialsSectionProps {
  realizationCertificate?: RealizationCertificate,
  ouvrageCertificate?: OuvrageCertificate
}

export const SocialsSection = (props: SocialsSectionProps) => {
  const {realizationCertificate, ouvrageCertificate} = props

  return (
    <Stack sx={{
      width: "100%", 
      backgroundColor: theme.colors?.primary, paddingY: "24px", backgroundImage: `url(${treeRingsImg})`,
      backgroundPosition: '5vw -33vw', backgroundRepeat: 'no-repeat', backgroundSize: '70vw 65vw',
      }} direction="row" justifyContent="center">
      <SocialsCard realizationCertificate={realizationCertificate} ouvrageCertificate={ouvrageCertificate}/>
    </Stack>
  );
}
