import {Avatar, Card, CardActions, CardContent, CardHeader, CardMedia, Stack, Typography} from "@mui/material";
import {Button} from '@komune-io/g2-components';
import {RealizationCertificate} from "../../model";
import defaultImg from "../../asset/13208_small.png";
import {useMemo} from "react";
import { WoodSpecies, externalFileUrl } from "@tracabois/domain";

interface RealizationCertificateCardProps {
  item?: RealizationCertificate
  onClick: (event: RealizationCertificate) => void
}

export const RealizationCertificateCard = (props: RealizationCertificateCardProps) => {
  const {item, onClick} = props

  const ouvrageNames = useMemo(() => {
    let ouvrages = item?.ouvrages?.map((it: any) => it.name)
    if(!ouvrages || ouvrages.length === 0) {
      ouvrages =  [" - "]
    }
    return ouvrages.join(", ")
  }, [item?.ouvrages])
  let img = useMemo(() => {
    if(item?.images && item?.images.length > 0) {
      return externalFileUrl(item.images[0])
    } else {
      return defaultImg
    }
  }, [item?.images])

  return (
    <Card sx={{height: "100%"}}>
      <CardHeader
        title={item?.name}
      />
      <CardMedia
        component="img"
        height="194"
        image={img}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {ouvrageNames}
        </Typography>
      </CardContent>
      <CardActions>
        <Stack justifyContent="space-between" alignItems="flex-end" direction="row" width="100%">
          <Stack>
            <Button onClick={() => item && onClick(item)}>VOIR</Button>
          </Stack>
          <Stack direction="row"  alignItems="flex-end" spacing={1}>
            <Typography variant="h4" color="text.secondary">
              {item?.indicators.wood}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              m3
            </Typography>
          </Stack>
          <Stack>
            {item?.species.map((species: WoodSpecies) => (
              <Avatar alt={species.name} src={externalFileUrl(species.img)} />
            ))}
          </Stack>
        </Stack>
      </CardActions>
    </Card>
  )
}

