import {OuvrageId, RealizationId} from "@tracabois/domain";
import {NavigateFunction} from "react-router-dom";

const listRealization = (navigate: NavigateFunction) => {
  return navigate(`/`);
};

const viewRealization = (realizationId: RealizationId, navigate: NavigateFunction) => {
  return navigate(`/realizations/${realizationId}`);
};

const viewOuvrage = (id: OuvrageId, navigate: NavigateFunction) => {
  return navigate(`/ouvrages/${id}`);
};

export const goto = {
  listRealization: listRealization,
  viewRealization: viewRealization,
  viewOuvrage: viewOuvrage,
};
