import { makeG2STyles } from "@komune-io/g2";
import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import { FacebookIcon, LinkedinIcon } from "@tracabois/components";
import { externalFileUrl, theme } from "@tracabois/domain";
import objectToGetParams from "@tracabois/domain/src/utils/objectToGetParams";
import { RealizationCertificate, OuvrageCertificate } from "App/model";
import { useEffect } from "react";

const useStyles = makeG2STyles()(
  () => ({
    icon: {
      width: '50px',
      height: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      verticalAlign: 'center'
    },
  }))
  
interface SocialsCardProps {
  realizationCertificate?: RealizationCertificate,
  ouvrageCertificate?: OuvrageCertificate
}

export const SocialsCard = (props: SocialsCardProps) => {
  const {realizationCertificate, ouvrageCertificate} = props
  const {classes} = useStyles()
  const url = window.location.href
  const title = realizationCertificate?.name || ouvrageCertificate?.name || "Certificat bois local"
  const summary = "Certificat de réalisation d'ouvrage de bois local"
  const imageUrl = externalFileUrl(realizationCertificate?.images[0]) || window.location.origin + "/favicon.ico"

  const shareOnLinkedin = () => {
    const linkedinUrl = 'https://linkedin.com/shareArticle' + objectToGetParams({ url: url, title:title, summary: summary, source: 'Boislocal' })
    window.open(linkedinUrl)
  }
  const shareOnFacebook = () => {
    const facebookUrl = "https://www.facebook.com/sharer/sharer.php" + objectToGetParams({ u: url })
    window.open(facebookUrl)
  }

  useEffect(() => {
    const metaDescriptionTag = document.querySelector("meta[name='description']")
    metaDescriptionTag?.setAttribute('property', "og:description")
    metaDescriptionTag?.setAttribute('content', summary)
  
    const tags = [
      { name: "url", content: window.location.href },
      { name: "type", content: "website" },
      { name: "locale", content: "fr_FR" },
      { name: "title", content: title },
      { name: "image", content: imageUrl },
    ]
  
    const headTag = document.querySelector("head")

    tags.forEach(tag => {
      const metaTag = document.createElement("meta")
      metaTag.setAttribute('name', tag.name)
      metaTag.setAttribute('property', "og:" + tag.name)
      metaTag.setAttribute('content', tag.content)
      headTag?.appendChild(metaTag)
    })
  }, [summary, title, imageUrl])

  return (
    <Card sx={{height: "100%", borderRadius: "24px", padding: "8px 48px" }}>
      <CardHeader
        title={"Partager ce certificat"}
      />
      <CardContent>
        <Stack direction="row" justifyContent="center" alignItems="center" gap="20px">
          <FacebookIcon color={theme.colors?.primary} onClick={shareOnFacebook} className={classes.icon}/>
          <LinkedinIcon color={theme.colors?.primary} onClick={shareOnLinkedin} className={classes.icon}/>
        </Stack>
      </CardContent>
    </Card>
  )
}
