import {Stack, Typography, useMediaQuery} from "@mui/material";
import {LogoWithTitle} from "@tracabois/components";

export interface CertificateHeaderProps {
  title: string
  certificateType: string
  productType?: string
}

export const CertificateHeader = (props: CertificateHeaderProps) => {
  const {title, certificateType, productType} = props
  const isTablet = useMediaQuery('(max-width:800px)')
  const isMobile = useMediaQuery('(max-width:500px)')
  const fullTitle = productType ? `${title} - ${productType}` : title
  return (
  <Stack sx={{
    display: 'flex', flexDirection: 'row', 
    justifyContent: 'space-between', flexWrap: 'nowrap', gap: '28px',
    borderBottom: '0.5px solid #E0E0E0', 
    margin: isTablet ? '0px 24px 24px 24px' : '0px 48px 24px 48px', 
    paddingBottom: '24px',
  }}>
    <LogoWithTitle sx={{maxWidth: "500px"}} isMobile={isMobile}/>
    <Stack
      direction={isTablet ? "column" : "row"}
      justifyContent={isTablet ? "flex-end" : "space-between"}
      alignItems={isTablet ? "flex-end" : "center"}
      flexWrap="wrap"
      width="100%"
      sx={{
        gap: "15px",
      }}
    >
      <Typography variant={isMobile ? 'h6' : (isTablet ? 'h5' : 'h4')} textAlign={isMobile ? 'end' : 'start'} sx={{color: "secondary.main"}}>
        {fullTitle}
      </Typography>
      <Typography sx={{
        color: "primary.main", 
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "32px",
      }}>
        {certificateType}
        </Typography>
    </Stack>
  </Stack>
  )
}
