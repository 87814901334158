import {Stack, Typography, styled, useMediaQuery} from "@mui/material";
import {ReactElement} from "react";
import treeRingsImg from "../../../asset/tree_rings.svg";
import { theme } from "@tracabois/domain";

export interface CertificateBlockProps {
  title?: string
  withBackground?: boolean
  withBackgroundImage?: boolean
  withVerticalPadding?: boolean
  withHorizontalMargin?: boolean
  withBottomMargin?: boolean
  withBorderRadius?: boolean
  withBoxShadow?: boolean
  narrower?: boolean
  children: ReactElement | ReactElement[]
}

const StyledBlockStack = styled(Stack)({
  '& > *:first-child': {
    border: 'none!important',
    width: '100%'
  },
})

export const CertificateBlock = (props: CertificateBlockProps) => {
  const {
    children, 
    title, 
    withBackground = false,
    withBackgroundImage = false,
    withVerticalPadding = false, 
    // withHorizontalMargin = false,
    withBottomMargin = false,
    withBorderRadius = false,
    withBoxShadow = false,
    // narrower = false,
  } = props
  const isTablet = useMediaQuery('(max-width:800px)')
  const sxBackgroundImage = withBackgroundImage ? {
    backgroundImage: `url(${treeRingsImg})`,
    backgroundPosition: '50vw -10vw', 
    backgroundRepeat: 'no-repeat', 
    backgroundSize: '70vw 65vw'
  } : {}
  // const marginX = withHorizontalMargin ? (isTablet ? 20 : (narrower ? 62 : 48)) : 0
  return (
    <Stack direction="column"
    sx={{
      backgroundColor: withBackground ? theme.colors?.secondary : withBoxShadow ? "#FFF":"none",
      color: withBackground ? "secondary.contrastText" : "#352328",
      ...sxBackgroundImage
    }}
    // marginX={marginX + "px"}
    marginBottom={withBottomMargin ? '72px' : '0'}
    paddingY={withVerticalPadding ? (isTablet ? "24px" : "48px") : "0"}
    boxShadow={withBoxShadow ? "rgba(0, 0, 0, 0.16) 0px 4px 8px" : "none"}
    borderRadius={withBorderRadius ? "24px" : "none"}
    width={"100%"}
    overflow="auto">
      {title && (
      <Stack paddingY='32px'>
        <Typography 
          sx={{textAlign: "center"}}
          variant={isTablet ? "h5" : "h4"}
        >
          {title}
        </Typography>
      </Stack>
      )}
      <StyledBlockStack 
        direction="row"
        flexWrap={isTablet ? "wrap" : "nowrap"}
        justifyContent="space-between"
        gap = "5px"
      >
        {children}
      </StyledBlockStack>
    </Stack>
  )
}
