import { useMediaQuery } from "@mui/material";
import { RemovalZone, RemovalZoneCardStack } from "@tracabois/domain";
import { CertificateSection } from "App/template/Certificate/Section";

export interface RemovalZoneCardProps {
  items: RemovalZone[]
}

export const RemovalZoneSection = (props: RemovalZoneCardProps) => {
  const {items} = props;
  const isMobile = useMediaQuery('(max-width:800px)')
  if (items.length <= 0) return (<></>)
  return (
    <CertificateSection title="Origines" align={isMobile ? "left" : "center"}>
      <RemovalZoneCardStack items={items}/>
    </CertificateSection>
  );
}
