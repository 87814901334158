import connect from "./Connect"
import { AppRouter } from "../router";

interface AppProps {
}

const App = (_: AppProps) => {
  return (
    <AppRouter />
  );
};

export default connect(App);
