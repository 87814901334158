import {CertificateHeader} from "../../template/Certificate/Header";
import {CertificateColumn} from "../../template/Certificate/Column";
import {CertificateBody} from "../../template/Certificate/Body";
import {CertificatePage} from "../../template/Certificate/Page";
import {RealizationCertificate} from "../../model";
import {ActorTransformationCard} from "../Actor/ActorTransformationCard";
import {OuvrageRefsSection} from "./OuvrageRefsSection";
import { SocialsSection } from "../socials/SocialsSection";
import { CertificateBlock } from "App/template/Certificate/Block";
import { RealizationRefsSection } from "./RealizationRefsSection";
import { ImageSection } from "./ImageSection";
import { RemovalZoneSection } from "./RemovalZoneSection";
import { RealizationCertificationLinkCertificateSection } from "./RealizationCertificationLinkCertificateSection";
import { DescriptionSection } from "./DescriptionSection";
import { IndicatorSection } from "./IndicatorSection";

export interface RealizationCertificateProps {
  item: RealizationCertificate
}

export const RealizationCertificateSection = (props: RealizationCertificateProps) => {
  const {item} = props;

  return (
    <CertificatePage>
      <CertificateHeader title={item.name} certificateType="Réalisation"/>
      <CertificateBody image={item.images[0]}>
        <CertificateBlock withVerticalPadding withHorizontalMargin withBottomMargin withBorderRadius withBoxShadow>
          <CertificateColumn>
            <IndicatorSection indicators={item.indicators}/>
            <DescriptionSection description={item.description}/>
            <ActorTransformationCard title={"Mise en oeuvre"} items={item.moes}/>
            <ActorTransformationCard title={"Réalisation"} items={item.actors}/>
          </CertificateColumn>
          <CertificateColumn>
            <RemovalZoneSection items={item.removalZones}/>
            <RealizationCertificationLinkCertificateSection items={item.certifications}/>
          </CertificateColumn>
        </CertificateBlock>
        <OuvrageRefsSection item={item}/>
        <ImageSection images={item.images} title="Photos de la réalisation"/>
        <SocialsSection realizationCertificate={item}/>
        <RealizationRefsSection id={item.id} />
      </CertificateBody>
    </CertificatePage>
  );
}
