import { RealizationIndicator, RealizationIndicatorGrid } from "@tracabois/domain";
import { CertificateSection } from "App/template/Certificate/Section";

export interface IndicatorCardProps {
  indicators: RealizationIndicator
}

export const IndicatorSection = (props: IndicatorCardProps) => {
  const {indicators} = props;
  if (!indicators) return (<></>)
  return (
    <CertificateSection>
      <RealizationIndicatorGrid indicators={indicators}/>
    </CertificateSection>
  );
}
