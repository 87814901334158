import { useMediaQuery } from "@mui/material";
import { RealizationCertificationLink, RealizationCertificationLinkCardStack } from "@tracabois/domain";
import { CertificateSection } from "App/template/Certificate/Section";

export interface RealizationCertificationLinkCertificateProps {
  items?: RealizationCertificationLink[]
}

export const RealizationCertificationLinkCertificateSection = (props: RealizationCertificationLinkCertificateProps) => {
  const {items} = props;
  const isMobile = useMediaQuery('(max-width:800px)')
  if (!items || items.length <= 0) return (<></>)
  return (
    <CertificateSection title="Niveau de reconnaissance" align={isMobile ? "left" : "center"}>
      <RealizationCertificationLinkCardStack items={items} editable={false}/>
    </CertificateSection>
  );
}
