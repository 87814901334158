import {OuvrageCertificateRef, RealizationCertificate} from "../../model";
import {Stack, useMediaQuery} from "@mui/material";
import {goto} from "../../../router/certificate.goto";
import {useNavigate} from "react-router";
import {OuvrageRefSection} from "./OuvrageRefSection";
import { CertificateBlock } from "App/template/Certificate/Block";

export interface OuvrageRefsCardProps {
  item: RealizationCertificate
}

export const OuvrageRefsSection = (props: OuvrageRefsCardProps) => {
  const {item} = props;
  const isMobile = useMediaQuery('(max-width:800px)')
  const navigate = useNavigate()
  if (item.ouvrages.length <= 0) return (<></>)
  return (
    <CertificateBlock title="Ouvrages de la réalisation" 
    withBackground withBottomMargin withBoxShadow
    withHorizontalMargin={!isMobile} narrower
    withBorderRadius={!isMobile}>
      <Stack display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="flex-start" gap="20px"
      padding="0px 24px 32px 24px" sx={{overflowX: 'auto'}}>
      {
        item.ouvrages.map((ouvrage: OuvrageCertificateRef) => (
          <Stack alignSelf="stretch">
            <OuvrageRefSection
              item={ouvrage}
              onClick={it => goto.viewOuvrage(it.id, navigate)}
            />
          </Stack>
        ))
      }
      </Stack>
    </CertificateBlock>
  );
}
