import {AppLoading} from "@tracabois/components";
import {useRealizationCertificatePageQuery} from "../../api";
import {Grid} from "@mui/material";
import {RealizationCertificate} from "../../model";
import {RealizationCertificateCard} from "../../components/RealizationCertificateCard/RealizationCertificateCard";
import {goto} from "../../../router/certificate.goto";
import {useNavigate} from "react-router-dom";
import QueryString, {parse} from "qs";
import {OffsetPagination} from "@tracabois/utils";

export interface RealizationProps {}

function urlParamsNumber(params: QueryString.ParsedQs, name: string): number | undefined {
  const value = urlParams(params, name)
  return value ? Number(value) : undefined
}

function urlParams(params: QueryString.ParsedQs, name: string): string | undefined {
  if (typeof params[name] === "string") {
    return params[name] as string;
  } else {
    return undefined
  }
}

export const Realizations = (_: RealizationProps) => {
  const navigate = useNavigate()
  const params = parse(window.location.search, { ignoreQueryPrefix: true });
  const organizationId = urlParams(params, "organizationId");
  const realizationId = urlParams(params, "realizationId");
  const limit = urlParamsNumber(params, "limit");
  const offset = urlParamsNumber(params, "offset");
  const pagination: OffsetPagination | undefined = (!!limit || !!offset) ?  {
    limit: limit ?? 10,
    offset: offset ?? 0
  } : undefined
  const realizationResult = useRealizationCertificatePageQuery({
    pagination: pagination,
    filters: {
      actor: organizationId,
      realizationId: realizationId
    }
  })
  if(realizationResult.isLoading) {
    return (<AppLoading />)
  }
  const items: RealizationCertificate[]  = realizationResult.data?.items ?? []
  if(organizationId && items.length == 1) {
    const item = items[0]
    return (<RealizationCertificateCard onClick={() => goto.viewRealization(item.id, navigate)} item={items[0]} />)
  }
  return (
    <Grid container spacing={2}>
      {items.map((item) => (
        <Grid item sm={12}  md={3}>
          <RealizationCertificateCard onClick={() => goto.viewRealization(item.id, navigate)} item={item} />
        </Grid>
      ))}
    </Grid>
  );
}
