import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {PageQuery, PageResult} from "@tracabois/utils";
import {
  OuvrageCertificate,
  OuvrageCertificateGetQuery,
  OuvrageCertificateGetResult,
  RealizationCertificate, RealizationCertificateGetQuery, RealizationCertificateGetResult
} from "../model";
import {authQuery, config} from "@tracabois/domain";

export const CertificateApi = createApi({
  reducerPath: 'certificateApi',
  baseQuery: authQuery({baseUrl: config.baseUrl}),
  endpoints: (builder) => ({
    ouvrageCertificateGet: builder.query<OuvrageCertificate | undefined, OuvrageCertificateGetQuery>({
      query: (body: OuvrageCertificateGetQuery) => ({
        url: `ouvrageCertificateGet`,
        method: 'POST',
        body: body
      }),
      transformResponse: (response: OuvrageCertificateGetResult[]) => response[0]?.item as OuvrageCertificate,
    }),
    realizationCertificateGet: builder.query<RealizationCertificate, RealizationCertificateGetQuery>({
      query: (body: RealizationCertificateGetQuery) => ({
        url: `realizationCertificateGet`,
        method: 'POST',
        body: body
      }),
      transformResponse: (response: RealizationCertificateGetResult[]) => response[0]?.item as RealizationCertificate,
    }),
    realizationCertificatePage: builder.query<PageResult<RealizationCertificate>, PageQuery>({
      query: (page: PageQuery) => ({
        url: `realizationCertificatePage`,
        method: 'POST',
        body: page
      }),
      transformResponse: (response: PageResult<RealizationCertificate>[]) => response[0],
    }),
  }),
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true
})

export const {
  useOuvrageCertificateGetQuery,
  useRealizationCertificateGetQuery,
  useRealizationCertificatePageQuery,
} = CertificateApi
