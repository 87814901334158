import {MapContainer, TileLayer} from "react-leaflet"
import {LatLngBounds} from "leaflet";
import GeoJson from "./GeoJson";
import { FeatureCollection } from "geojson";

export interface MapGeoJsonProps {
  geoJson: FeatureCollection
  bounds?: LatLngBounds
}

export const MapGeoJson = (props: MapGeoJsonProps) => {
  const {geoJson, bounds} = props
  return (
    <MapContainer bounds={bounds?.pad(0.5)} scrollWheelZoom={true} style={{height:400, width: "100%"}}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
        maxZoom={20}
      />
      <GeoJson geoJson={geoJson}/>
    </MapContainer>
  );
};
