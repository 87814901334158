import {Card, CardContent, CardMedia, Typography} from "@mui/material";
import defaultImg from "./asset/img.png"
import { RealizationCertificate } from "App/model";
import { externalFileUrl } from "@tracabois/domain";

export interface RealizationRefCardProps {
  item: RealizationCertificate
  onClick: () => void
}

export const RealizationRefSection = (props: RealizationRefCardProps) => {
  const {item, onClick} = props;
  const img = externalFileUrl(item.images[0]) ?? defaultImg
  return (
    <Card onClick={() => onClick()} sx={{width: "240px", cursor: "pointer", height: "100%", borderRadius:"24px"}}>
      <CardMedia image={img} sx={{ width: 240, height: 150 }}/>
      <CardContent>
        <Typography variant="h6" color="secondary.main" sx={{overflowX: "auto"}}>{item.name}</Typography>
        {item.indicators.wood > 0 && (
          <Typography color="primary.main">{item.indicators.wood} m3</Typography>
        )}
      </CardContent>
    </Card>
  );
}
