import {RealizationCertificate} from "../../model";
import {Stack} from "@mui/material";
import {goto} from "../../../router/certificate.goto";
import {useNavigate} from "react-router";
import { useRealizationCertificatePageQuery } from "App/api";
import { RealizationRefSection } from "./RealizationRefSection";
import { RealizationId } from "@tracabois/domain";
import { CertificateBlock } from "App/template/Certificate/Block";

export interface RealizationRefsCardProps {
  id: RealizationId
}

export const RealizationRefsSection = (props: RealizationRefsCardProps) => {
  const {id} = props;
  const navigate = useNavigate()

  const otherRealizations = useRealizationCertificatePageQuery({
    pagination: {
      limit: 6,
      offset: 0
    },
    filters: {
      actor: undefined,
      realizationId: undefined
    }
  }).data?.items?.filter(item => item.id != id) || []

  if (!otherRealizations || otherRealizations.length <= 0) return (<></>)
  return (
    <CertificateBlock title="Projets similaires" withBackground withBackgroundImage>
      <Stack display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="flex-start" gap="20px"
      padding="0px 24px 32px 24px" sx={{overflowX: 'auto'}}>
      {
        otherRealizations.map((item: RealizationCertificate) => (
          <Stack alignSelf="stretch" key={"RealizationRefSection-"+item.id}>
              <RealizationRefSection
                item={item}
                onClick={() => goto.viewRealization(item.id, navigate)} 
              />
          </Stack>
        ))
      }
      </Stack>
    </CertificateBlock>
  );
}
