import {OrganizationRef} from "../../../model";
import {ActorCard} from "../ActorCard";
import {CertificateSection} from "../../../template/Certificate/Section";
import { Stack, useMediaQuery } from "@mui/material";

export interface ActorTransformationCardProps {
  title: string,
  items: OrganizationRef[]
}

export const ActorTransformationCard = (props: ActorTransformationCardProps) => {
  const {title, items} = props;
  const isTablet = useMediaQuery('(max-width:800px)')
  if (!items || items.length <= 0) return (<></>)
  return (
    <CertificateSection title={title}>
      <Stack display="grid" gridAutoFlow="row dense"
       gridTemplateColumns="repeat(auto-fill, 250px)" gap="24px 12px"
       marginLeft={isTablet ? "12px" : "O"}>
        {items.map((organization, index) => (
          <ActorCard item={organization} key={index}/>
        ))}
      </Stack>
    </CertificateSection>
  );
}
