import {Box} from "@mui/material";
import React from "react";

export interface CertificatePageProps {
  children?: React.ReactNode;
}

export const CertificatePage = (props: CertificatePageProps) => {
  const {children} = props
  return (
    <Box
      sx={{
        paddingTop: "20px",
        maxWidth: "1200px",
        width: "100%",
      }}
    >
      {children}
    </Box>
  )
}