import {Stack, Typography, useMediaQuery} from "@mui/material";
import {ReactElement} from "react";

type CertificateSectionAlign = 'left' | 'center' | 'right'

export interface CertificateSectionProps {
  title?: string
  withBackground?: boolean
  align?: CertificateSectionAlign
  children: ReactElement | ReactElement[]
}

export const CertificateSection = (props: CertificateSectionProps) => {
  const {title, children, withBackground = false, align = 'left'} = props
  const isMobile = useMediaQuery('(max-width:500px)')
  const sx = withBackground ? {backgroundColor: "secondary.main", color: "secondary.contrastText"} : {}
  return (
    <Stack direction="column" marginBottom={2} width={"100%"} sx={sx}>
      {title && (
        <Stack marginTop={2} marginLeft={2}>
          <Typography 
            sx={{textAlign: align}} 
            variant={isMobile ? "h6" : "h5"}
          >
            {title}
          </Typography>
        </Stack>
      )}
      <Stack margin={2}>
        {children}
      </Stack>
    </Stack>
  )
}
