import {useEffect} from "react";
import { useLeafletContext } from "@react-leaflet/core";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import * as geojson from "geojson";
import {createLayersFromJson} from "../geoJsonLayer";

interface GeoJsonProps {
  geoJson: geojson.FeatureCollection
}

const GeoJson = (props: GeoJsonProps) => {
  const {geoJson} = props
  const context = useLeafletContext();

  useEffect(() => {
    const {map} = context;
    createLayersFromJson(geoJson, map)
  }, [context]);

  return null;
};

export default GeoJson;
