import {Stack, useMediaQuery} from "@mui/material";
import React from "react";

export interface CertificateColumnProps {
  children?: React.ReactNode;
}

export const CertificateColumn = (props: CertificateColumnProps) => {
  const {children} = props
  const isTablet = useMediaQuery('(max-width:800px)')
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      width={isTablet ? "100%" : "fit-content"}
      minWidth={isTablet ? "100%" : "400px"}
      borderLeft={isTablet ? 'none' : "1px solid #E0E0E0"}
      padding={isTablet ? "0px 24px" : "0px 48px"}
    >
      {children}
    </Stack>
  )
}
