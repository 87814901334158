import thunk from "redux-thunk";
import {
  OrganizationTypeApi,
  OuvrageApi,
  ProductApi,
  ProductTypeApi,
  RealizationApi,
  RemovalZoneApi, StatsApi,
  WoodSpeciesApi
} from "@tracabois/domain";
import {BtmcOrganizationApi} from "@tracabois/domain";
import {Reducer} from "redux";
import {CertificateApi} from "../App/api";
import {initRedux} from "@tracabois/utils";

type ReducersType = { [key: string]: Reducer; }

interface Reducers extends ReducersType {

}

const reducers: Reducers = {
  [ProductApi.reducerPath]: ProductApi.reducer,
  [RealizationApi.reducerPath]: RealizationApi.reducer,
  [OuvrageApi.reducerPath]: OuvrageApi.reducer,
  [OrganizationTypeApi.reducerPath]: OrganizationTypeApi.reducer,
  [ProductTypeApi.reducerPath]: ProductTypeApi.reducer,
  [WoodSpeciesApi.reducerPath]: WoodSpeciesApi.reducer,
  [RemovalZoneApi.reducerPath]: RemovalZoneApi.reducer,
  [BtmcOrganizationApi.reducerPath]: BtmcOrganizationApi.reducer,
  [StatsApi.reducerPath]: StatsApi.reducer,
  [CertificateApi.reducerPath]: CertificateApi.reducer,

};

export const  { store, history, reducer } = initRedux<typeof reducers>(
  reducers,
  [thunk,
    ProductApi.middleware,
    RealizationApi.middleware,
    OuvrageApi.middleware,
    OrganizationTypeApi.middleware,
    ProductTypeApi.middleware,
    WoodSpeciesApi.middleware,
    RemovalZoneApi.middleware,
    BtmcOrganizationApi.middleware,
    StatsApi.middleware,
    CertificateApi.middleware,
  ]
);

export type State = ReturnType<typeof reducer>;

