import {OuvrageCertificateRef} from "../../model";
import {Card, CardContent, CardMedia, Typography} from "@mui/material";
import defaultImg from "./asset/img.png"
import { externalFileUrl } from "@tracabois/domain";

export interface OuvrageRefCardProps {
  item: OuvrageCertificateRef
  onClick: (ref: OuvrageCertificateRef) => void
}

export const OuvrageRefSection = (props: OuvrageRefCardProps) => {
  const {item, onClick} = props;
  const img = externalFileUrl(item.images[0]) ?? defaultImg
  return (
    <Card onClick={() => onClick(item)} sx={{cursor: "pointer", height: "100%", borderRadius:"24px"}}>
      <CardMedia image={img} sx={{ width: 240, height: 150 }}/>
      <CardContent>
        <Typography variant="h6" color="secondary.main">{item.name}</Typography>
        {item.indicators.wood > 0 && (
          <Typography color="primary.main">{item.indicators.wood} m3</Typography>
        )}
      </CardContent>
    </Card>
  );
}
