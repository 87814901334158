import {useParams} from "react-router";
import {OuvrageCertificateSection} from "../../components/ouvrage/OuvrageCertificateSection";
import {AppLoading} from "@tracabois/components";
import {useOuvrageCertificateGetQuery} from "../../api";

export interface HomeProps {
}

export const OuvragePage = (_: HomeProps) => {
  const {ouvrageId = ""} = useParams<{ ouvrageId: string }>();
  const result = useOuvrageCertificateGetQuery({ id: ouvrageId })

  if(result.isLoading) {
    return (<AppLoading />)
  }
  return (
    <div style={{
      background: "linear-gradient(180deg, rgba(87, 69, 74, 0.10) 7.96%, rgba(87, 69, 74, 0.00) 18.4%, #FFFDF8 32.84%, #FFFDF8 64.51%)",
      display: "flex",
      justifyContent: "center",
      }}>
      <OuvrageCertificateSection item={result.data!!} />
    </div>
  );
}
