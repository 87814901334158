import {Router} from "@komune-io/g2-providers";
import {Route} from "react-router-dom";
import {RealizationPage} from "App/pages/realization";
import {OuvragePage} from "../App/pages/ouvrage";
import {RealizationsPage} from "../App/pages/realizations";

export const AppRouter = () => {
  return (
    <Router>
      <Route path="/" element={<RealizationsPage/>}/>
      <Route path="/realizations/:realizationId" element={<RealizationPage/>}/>
      <Route path="/ouvrages/:ouvrageId" element={<OuvragePage/>}/>
    </Router>
  );
};
