import { MarkdownField } from "@komune-io/g2";
import { CertificateSection } from "App/template/Certificate/Section";

export interface DescriptionCardProps {
  description?: string
}

export const DescriptionSection = (props: DescriptionCardProps) => {
  const {description} = props;
  if (!description) return (<></>)
  return (
    <CertificateSection title="Présentation">
      <MarkdownField markdown={description} readOnly/>
    </CertificateSection>
  );
}
