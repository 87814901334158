import { MapGeoJson } from "@tracabois/components";
import { CertificateSection } from "App/template/Certificate/Section";
import { FeatureCollection } from "geojson";
import { LatLngBounds } from "leaflet";

export interface MapSectionProps {
  geoJson: FeatureCollection
  bounds?: LatLngBounds
}

export const MapSection = (props: MapSectionProps) => {
  const {geoJson, bounds} = props;
  if (geoJson.features.length === 0) {return <></>}
  return (
    <CertificateSection title="Tracé">
      <MapGeoJson geoJson={geoJson} bounds={bounds}/>
    </CertificateSection>
  );
}
